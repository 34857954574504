<template>
  <v-row>
    <v-col
      v-for="(item, index) in 3"
      :key="index"
      cols="12"
      lg="4"
      md="4"
      sm="6"
    >
      <template>
        <v-sheet
          color="grey lighten-4"
          class="pa-0"
        >
          <v-skeleton-loader
            max-height="150px"
            class="mx-auto"
            type="article"
          ></v-skeleton-loader>
        </v-sheet>
      </template>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "HomeCatalogTypeSkeletonLayout",
};
</script>